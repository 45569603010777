import React from "react"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import theme from '../../config/theme'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/hero'
import { BigTitle, Title, TitleLight, SiteTitle } from '../components/titles'
import Button from '../components/buttons'
import Funcionalidad from '../components/funcionalidad'
import Planes from '../components/planes'
import Demo from '../components/demo'
import SVG from '../components/svg'

import PlanCard from '../components/plan-card'

const PlanGrid = styled.div`
  ${tw`pt-5 mb-20 md:pt-20`}
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${theme.breakpoints[1]}) {
    grid-template-columns: 1fr;
    padding: 0 2rem;
    grid-gap: 2rem;
    margin-top: 5rem;
  }
`

const Banner = styled.div`
  ${tw`p-5 mx-0 md:p-12 md:mx-32 mt-20`};
  display: flex;
  border-radius: 3rem;
  background-color: ${theme.colors.primary};
  color: white;
  position: relative;
  flex-direction: column;
  align-items: center;
  box-shadow: #441b5b 0 2rem 0;
  overflow: hidden; 
  svg {
    max-width: 20rem;
    margin: 3rem 0;
  }
  @media (max-width: ${theme.breakpoints[1]}) {
    margin-left: -1rem;
    margin-right: -1rem;
    border-radius: 0;
    svg {
      width: 100%;
      &:not(:first-child) {
        display: none;
      }
    }
  }
`

const BannerContent = styled.div`
  ${tw`pl-0 text-center md:pl-10`}
`

const IndexPage = props => (
  <Layout location={props.location}>
    <SEO title="Jornada10 | Sistema de registro horario" description="Aplicación para el registro de jornada laboral que cumple con la normativa legal española vigente desde mayo de 2019"/>
    <Hero>
      <SiteTitle>Jornada</SiteTitle>
      <BigTitle>Sistema de registro horario</BigTitle>
      <p>Registra el horario de tus trabajadores con tarjetas o llaveros contactless, móvil y/o huella dactilar.</p>
      <Button 
        to="/registro" 
        invert
        onclick={() => {
          trackCustomEvent({
            category: "Landing",
            action: "empieza_hero_btn",
          })
        }}
        >Empieza con tu espacio gratuitamente</Button>
    </Hero>
    <Funcionalidad>
      <Title>Monitorización<br/>Panel de control</Title>
      <ul>
      <li>Visualización web y móvil.</li>
        <li>Generación de informes mensuales.</li>
        <li>Días de baja, vacaciones o personales.</li>
        <li>Copias de seguridad durante 5 años.</li>
      </ul>
    </Funcionalidad>
    <Planes>
      <Title>Planes y servicios</Title>
      <PlanGrid>
          <PlanCard
            title="Micro"
            price="5"
            priceMeta="al mes por trabajador"
            content="Hasta tres trabajadores"
            month="true"
          />
          <PlanCard
            title="Micro Plus"
            price="15"
            priceMeta="al mes"
            content="De 4 a 10 trabajadores"
            featured
            month="true"
          />
          <PlanCard
            title="PYME"
            price="25"
            priceMeta="al mes"
            content="De 10 a 25 trabajadores"
            month="true"
          />
          <PlanCard
            title="PYME Plus"
            content="Más de 25 trabajadores"
            fullGrid
            featured
            month="true"
          />
        </PlanGrid>
        <TitleLight>Empieza con tu plan gratuitamente, añade trabajadores, aumenta progresivamente y paga a medida que generes registros.</TitleLight>
        <Banner>
          <SVG icon="contactless" width="40" fixed/>
          <BannerContent>
            <Title mb="0.5">Caja contactless (opcional)</Title>
            <h4>Dispositivo lector contactless + Llaveros + Tarjetas</h4>
            <p>El dispositivo es una caja que va conectada a la corriente y ya viene configurada con su conexión WIFI para transmitir los datos.</p>
            <p>Puede tener más de una caja, dependiendo de sus despachos o estancias, y cada trabajador podrá tener un llavero o una tarjeta contactless.</p>
            {/* <Button to="/contactless" invert>Más información</Button> */ }
            <Title mb="0.2">150,00€/caja</Title>
            <p><small>(Opcional) Si no desea el dispositivo, sus trabajadores podrán iniciar y parar la jornada con el móvil o con su ordenador.</small></p>
          </BannerContent>
        </Banner>
    </Planes>
    <Demo>
      <Title>Prueba Jornada10 ahora de manera totalmente gratuita.</Title>
      <p>Entra y toquetea todo lo que quieras sin compromiso. Añade trabajadores, mira el calendario de horas, como se genera los informes y mucho más!</p>
      <Button
        to="/demostracion"
        invert
        onClick={() => {
          trackCustomEvent({
            category: "Landing",
            action: "demostracion_seccio_btn",
          })
        }}
        >Prueba la demostración</Button>
    </Demo>
  </Layout>
)

export default IndexPage
